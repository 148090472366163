import { render, staticRenderFns } from "./signup-social.vue?vue&type=template&id=f260a078&"
import script from "./signup-social.vue?vue&type=script&lang=js&"
export * from "./signup-social.vue?vue&type=script&lang=js&"
import style0 from "./signup-social.vue?vue&type=style&index=0&module=true&lang=scss&"
import style1 from "./signup-social.vue?vue&type=style&index=1&scope=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCheckbox,VCol,VContainer,VIcon,VOverlay,VProgressCircular,VRow})
